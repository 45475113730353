<template>
  <div class="container-fluid">
    <div class="row">
      <div class="title">{{$t('dashboard')}}</div>
      <div class="col-md-12 col-lg-12">

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">

    </div>
  </div>
</template>

<script>
export default {
  name: "dashboard"
}
</script>

<style scoped></style>